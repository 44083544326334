import {useParams} from "react-router-dom";
import {Box, Card, CardMedia, IconButton, Modal, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import styles from './Warscroll.module.scss'
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import Warscroll from "./Warscroll";
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Brightness4, Brightness7} from "@mui/icons-material";

const labels = {
    mostEffective: "Most Effective",
    effective: "Effective",
    fine: "Fine",
    ineffective: "Ineffective",
    leastEffective: "Least Effective",
}
const ScrollCard = ({scroll, id, index, handleClick}) => {


    return <Draggable index={index} draggableId={id}>
        {(provided, snapshot) => <Card ref={(ref) => provided.innerRef(ref)}
                                       {...provided.draggableProps}
                                       {...provided.dragHandleProps}
                                       sx={{minWidth: "150px", width: "150px"}}
                                       onClick={handleClick}
        >
            <CardMedia image={scroll.bigImage} component={"img"}/>
        </Card>}
    </Draggable>
}

const Tier = ({children, id}) => {


    return <div style={{display: "flex", flexDirection: "row"}}>{labels[id]?
        <Box className={styles[id] + " " + styles.tierTitle}><Typography
            variant={"h4"} sx={{fontWeight:"600", justifyContent:"left"}}>{labels[id]}</Typography>
        </Box>:null}
        <Droppable droppableId={id} direction={"horizontal"}>

            {(dropProvided, dropSnapshot) => {
                return <Box ref={dropProvided.innerRef} className={styles.Column} sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: 1,
                    gap: "5px",
                    padding: "10px",
                    minHeight: "90px",
                    bgcolor: "background.info",
                    borderRadius: "10px",
                    overflow: "hidden",
                }}>
                    {children}
                    {dropProvided.placeholder}
                </Box>
            }}
        </Droppable>
    </div>
}

const Tierlist = ({warscrollData}) => {
    const {faction} = useParams()
    const warscrolls = warscrollData.filter(ws => ws.faction.find(f => f.toLocaleLowerCase().replace(/\s+/g, '-') === faction))
    const [items, setItems] = useState({
        mostEffective: [],
        effective: [],
        fine: [],
        ineffective: [],
        leastEffective: [],
        toSort: [...warscrolls.map(s => s.id)]
    })
    const [selectedWarscroll, setSelectedWarscroll] = useState()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [darkMode, setDarkMode] = useState(prefersDarkMode);

    const darkTheme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            ...(darkMode ? {
                info: {main: "rgba(255,204,0,0.27)"}
            } : {
                info: {main: "#d7cfc4"}
            })
        },
    });
    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    }

    const onDragEnd = (result) => {
        const {destination, source, draggableId} = result
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        const startRow = items[source.droppableId]
        const endRow = items[destination.droppableId]
        if (startRow === endRow) {
            const newRow = Array.from(startRow)
            newRow.splice(source.index, 1)
            newRow.splice(destination.index, 0, draggableId)
            setItems({
                ...items,
                [source.droppableId]: newRow
            })
            return;
        }
        const startRowIds = Array.from(startRow)
        startRowIds.splice(source.index, 1)
        const endRowIds = Array.from(endRow)
        endRowIds.splice(destination.index, 0, draggableId)
        setItems({
            ...items,
            [source.droppableId]: startRowIds,
            [destination.droppableId]: endRowIds,
        })
    }
    const handleOpen = (value) => {
        setSelectedWarscroll(value)
    }
    const handleClose = () => setSelectedWarscroll(undefined)

    return <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <div>
            <Modal open={!!selectedWarscroll} onClose={handleClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    border: '2px solid #fff',
                    boxShadow: 24,
                    height:"100%",
                    p: 1,
                    overflow: "scroll",
                    bgcolor: "background.paper"
                }}>
                    <Warscroll scroll={warscrolls.find(s => s.id === selectedWarscroll)}/>
                </Box>
            </Modal>
        </div>
        <DragDropContext
            onDragEnd={onDragEnd}>
            <div className={styles.Root}>
                {Object.entries(items).map(([row, scrolls]) => (
                    <Tier id={row} key={row}>
                        {scrolls.map((scroll, index) => <ScrollCard scroll={warscrolls.find(s => s.id === scroll)}
                                                                    index={index}
                                                                    row={row}
                                                                    key={scroll}
                                                                    id={scroll}
                                                                    handleClick={() => handleOpen(scroll)}/>)}
                    </Tier>
                ))}
            </div>
        </DragDropContext>
        <IconButton sx={{ml: 1}} onClick={toggleDarkMode} color="inherit">
            {darkMode ? <Brightness7/> : <Brightness4/>}
        </IconButton>

    </ThemeProvider>
}
export default Tierlist